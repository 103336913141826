@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@font-face {
    font-family: 'Telegraphem';
		src: url(/static/media/Telegraphem.b5731be2.otf);
    font-weight: normal;
    font-style: normal;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

